import MenuBar from "../../components/navBar";

const Home = () => {
  return (
    <div>
      <MenuBar />
      <div className="fullscreen-background"></div>
    </div>
  );
};

export default Home;
