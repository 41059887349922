import { Button, Image } from "react-bootstrap";
import { Form, FormGroup, Label, Input } from "reactstrap";

const DadosBancarios = (props: any) => {
  const { preview, handleImagemChange, setHandlePreview } = props;
  return (
    <div className="formulario">
      <div className="col-md-9 mt-2 bgWhite">
        <div className="col-md-6 text-BLACK fw-bolder">
          Inscrições: R$ 100,00.
          <br />
          Banco do Brasil
          <br /> Agencia: 3206-9
          <br /> C/C: 36682-0
          <br /> Nome: Associação Beneficente Irmãs Dorcas
          <br /> PIX:
          <br />
          <Image
            className="responsive img"
            alt="qr-code"
            src="../img/qrcode-pix.png"
          />
        </div>
        <div className="col-md-6 formulario">
          <Form>
            <FormGroup>
              <Label for="imagem">Selecione uma imagem:</Label>
              <Input
                type="file"
                id="imagem"
                accept=".jpg, .jpeg, .png, .pdf"
                onChange={setHandlePreview}
              />
            </FormGroup>
            <FormGroup hidden={!preview}>
              Preview:
              <br />
              <img
                id="preview"
                className="img-fluid"
                src={preview}
                width={300}
                height={300}
                alt="Preview da Imagem"
              />
            </FormGroup>
          </Form>
          <Button onClick={handleImagemChange} hidden={!preview}>
            Enviar Comprovante
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DadosBancarios;
