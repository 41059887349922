import MenuBar from "../../components/navBar";

export const InscricoesFinalizada = () => {
  const reload = () => {
    setTimeout(() => {
      window.location.href = "/";
    }, 5000);
  };
  reload();
  return (
    <div>
      <MenuBar />
      <div className="col-md-12 home">
        <div className="text-center col-md-12 mb-3 text-white">
          <h1>Inscricão Finalizada com sucesso</h1>
          <h2>Comprovante Enviado</h2>
        </div>
      </div>
    </div>
  );
};
