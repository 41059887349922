import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import Inscricoes from "../pages/incricoes/inscricoes";
import InscricoesUpload from "../pages/incricoes/upload";
import { InscricoesFinalizada } from "../pages/incricoes/finalizada";

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    {/* <Route path="/inscricao" element={<Inscricoes />} /> */}
    <Route path="/inscricaoUpload/:cpf" element={<InscricoesUpload />} />
    <Route path="/InscricaoFinal" element={<InscricoesFinalizada />} />
  </Routes>
);

export default AppRoutes;
