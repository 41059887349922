export default function MenuBar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <button
        style={{ margin: "10px" }}
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1"
      >
        <span className="navbar-toggler-icon"></span>
      </button>{" "}
      <a className="navbar-brand" href="/">
        CIBEA
      </a>
      <div
        className="collapse navbar-collapse"
        id="bs-example-navbar-collapse-1"
      >
        <ul style={{ margin: "10px" }} className="navbar-nav">
          {/* <li className="nav-item">
            <a className="nav-link fa-blink" href="/inscricao">
              Inscricões
            </a>
          </li> */}
          <li className="nav-item">
            <a className="nav-link" href="https://admcibea.ldtsistemas.com.br/">
              Painel
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
