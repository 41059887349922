import { useParams } from "react-router-dom";
import MenuBar from "../../components/navBar";
import React, { useState } from "react";
import { api } from "../../services/api";
import DadosBancarios from "../../components/dadosBancarios";

const InscricoesUpload = () => {
  const { cpf } = useParams();
  const [preview, setPreview] = useState("");
  const [imagem, setImagem] = useState<any>(null);

  const setHandlePreview = (e: any) => {
    setPreview(URL.createObjectURL(e.target.files[0]));
    setImagem(e.target.files[0]);
  };

  const handleImagemChange = (e: any) => {
    e.preventDefault();
    // const imagem = e.target.files[0];
    if (!imagem) {
      return;
    }
    const formData = new FormData();
    formData.append("file", imagem, imagem.name);

    api
      .post(`/inscricoes/upload/${cpf}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        window.location.href = "/InscricaoFinal";
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  return (
    <div>
      <MenuBar />
      <div className="col-md-12 fullscreen-background home">
        <div className="text-center col-md-12 mb-3 text-white">
          <h1>Inscricão Finalizada - CPF {cpf}</h1>
        </div>
        <DadosBancarios
          preview={preview}
          handleImagemChange={handleImagemChange}
          setHandlePreview={setHandlePreview}
        />
        <div className="formulario"></div>
      </div>
    </div>
  );
};

export default InscricoesUpload;
